import './App.css';
import Router from './components/routing/Router';
import { useEffect, useState } from 'react';
import { useAppContext } from './context/AppContext';
import CookieConsent from 'react-cookie-consent';
import AuthRouter from './components/routing/AuthRouter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
function App() {
	const { user, setUser } = useAppContext();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const userString = localStorage.getItem('user');
		if (userString && userString !== 'undefined') {
			const user = JSON.parse(userString);
			setUser(user);
		}
		setLoading(false);
	}, []);

	return (
		!loading && (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<div className='w-full h-full overscroll-x-contain overflow-x-hidden'>
					{user ? <Router /> : <AuthRouter />}
					<CookieConsent
						location='bottom'
						expires={999}
						overlay
						enableDeclineButton
						buttonText='Accept'
						declineButtonText='Decline'
						containerClasses='left-0 w-full md:w-auto md:p-4 bg-gray-100 shadow-lg text-center'
						buttonClasses='bg-green-500 text-white py-2 px-4 rounded cursor-pointer transition duration-300 ease-in-out md:mr-2'
						declineButtonClasses='bg-red-500 text-white py-2 px-4 rounded cursor-pointer transition duration-300 ease-in-out'
						overlayClasses='bg-black bg-opacity-50'
						contentClasses='text-gray-700'
						onDecline={() => window.location.reload()}>
						This website uses cookies to enhance your experience. By accepting,
						you consent to the use of cookies. Please note that some
						functionality may be limited if you choose not to accept cookies.
					</CookieConsent>
				</div>
			</LocalizationProvider>
		)
	);
}

export default App;
