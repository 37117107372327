import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
const InstallPrompt = () => {
	const [showInstallPrompt, setShowInstallPrompt] = useState(false);
	const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
	const isIos = () => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	};
	useEffect(() => {
		if (!isStandalone && isIos()) {
			setShowInstallPrompt(true);
		}
	}, []);
	return (
		showInstallPrompt && (
			<div className='fixed bottom-5 transition-all left-1/2 -translate-x-1/2 justify-between w-[95vw] bg-white p-5 rounded-lg shadow-md z-[1000] flex'>
				<div className='flex flex-col justify-center'>
					<img src='/icon-192x192.png' />
				</div>
				<p className='relative mx-2'>
					Enhance your experience with our free app! To install, tap the
					<IosShareIcon className='align-text-bottom mx-1' />
					button and then select "Add to Home Screen".
				</p>
				<IconButton
					className='absolute top-1 right-1'
					onClick={() => setShowInstallPrompt(false)}>
					<CloseIcon fontSize='.75rem' />
				</IconButton>
			</div>
		)
	);
};

export default InstallPrompt;
