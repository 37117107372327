import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

const EnabledSurveySuccessPopup = ({ open, onClose }) => {
	const handleClose = () => {
		onClose();
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} >
				<DialogTitle>Success!</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Diners will begin receiving your surveys!
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary' variant='contained'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default EnabledSurveySuccessPopup;
