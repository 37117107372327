import React, { useEffect, useState } from 'react';
import axios from '../../services/auth-header.js';
import {
	TextField,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Button,
	Stack,
} from '@mui/material';
import { useAppContext } from '../../context/AppContext.jsx';
import { Diner } from '../../models/Diner.js';

const Settings = () => {
	const { user, openToast } = useAppContext();

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		gender: '',
		zipCode: '',
	});

	const getDinerInfo = async () => {
		try {
			const response = await axios.get(`diner/${user.userId}`);
			const dinerResponse = response.data;
			if (dinerResponse) {
				setFormData({
					firstName: dinerResponse.firstName || '',
					lastName: dinerResponse.lastName || '',
					email: dinerResponse.email || '',
					gender: dinerResponse.gender || '',
					zipCode: dinerResponse.zipCode || '',
				});
			}
		} catch (error) {
			console.error('ERROR FETCHING DINER INFO:\n' + error);
		}
	};

	const isZipCodeValid = (zipCode) => zipCode.length === 5;

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		return emailRegex.test(email);
	};

	const handleChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
		console.log(formData);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!formData.firstName) {
			alert('First Name is required.');
			return;
		}

		if (!formData.lastName) {
			alert('Last Name is required.');
			return;
		}

		if (!isValidEmail(formData.email)) {
			alert('Invalid email. Valid email is required.');
			return;
		}

		if (!formData.gender) {
			alert('Gender is required.');
			return;
		}

		if (!isZipCodeValid(formData.zipCode)) {
			alert('Invalid zipCode. It must be 5 characters long.');
			return;
		}

		try {
			const diner = new Diner(
				user.userId,
				formData.firstName,
				formData.lastName,
				formData.email,
				formData.zipCode,
				formData.gender
			);
			const response = await axios.post('diner/update/', diner);
			if (response?.status === 200) {
				openToast('User info updated successfully', 'success');
			} else {
				openToast('An error occurred while updating user info', 'error');
			}
		} catch (err) {
			openToast(
				'Unable to process your request, please try again later',
				'error'
			);
			console.log(err);
		}
	};

	useEffect(() => {
		getDinerInfo();
	}, []);

	return (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='flex-grow overflow-y-scroll py-4 pb-40'>
			<form
				style={{
					backgroundColor: 'white',
					padding: '20px',
					borderRadius: '8px',
				}}>
				<FormControl fullWidth>
					<TextField
						label='First Name'
						name='firstName'
						value={formData.firstName}
						onChange={handleChange}
						margin='normal'
						required
					/>
				</FormControl>

				<FormControl fullWidth>
					<TextField
						label='Last Name'
						name='lastName'
						value={formData.lastName}
						onChange={handleChange}
						margin='normal'
						required
					/>
				</FormControl>

				<FormControl fullWidth>
					<TextField
						label='Email'
						name='email'
						type='email'
						value={formData.email}
						onChange={handleChange}
						margin='normal'
						required
						disabled
					/>
				</FormControl>

				<FormControl fullWidth>
					<InputLabel>Gender</InputLabel>
					<Select
						label='Gender'
						name='gender'
						value={formData.gender}
						onChange={handleChange}
						required>
						<MenuItem key={'M'} value='M'>
							Male
						</MenuItem>
						<MenuItem key='F' value='F'>
							Female
						</MenuItem>
						<MenuItem key='O' value='O'>
							Other
						</MenuItem>
						<MenuItem key='P' value={'P'}>
							Prefer not to say
						</MenuItem>
					</Select>
				</FormControl>

				<FormControl fullWidth>
					<TextField
						label='Zipcode'
						name='zipCode'
						value={formData.zipCode}
						onChange={handleChange}
						margin='normal'
						inputProps={{ maxLength: 5, minLength: 5 }}
						required
					/>
				</FormControl>

				<Button variant='contained' color='primary' onClick={handleSubmit}>
					Save Changes
				</Button>
			</form>
		</Stack>
	);
};

export default Settings;
