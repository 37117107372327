export class Group {
	groupId;
	groupName;
	ownerId;
	createdDate;
	updatedDate;
	constructor(groupId, groupName, ownerId) {
		this.groupId = groupId;
		this.groupName = groupName;
		this.ownerId = ownerId;
		this.createdDate = new Date();
		this.updatedDate = new Date();
	}
}
