import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use((request) => {
	const token = localStorage.getItem('token');
	request.headers['Authorization'] = 'Bearer ' + token;
	return request;
});

axios.interceptors.response.use(
	(response) => {
		//TODO get the new user info using their user id instead of what is in local storage
		if (response?.data?.accessToken) {
			localStorage.setItem('user', JSON.stringify(response.data.user));
			localStorage.setItem('token', response.data.accessToken);
		}
		return response;
	},
	async (error) => {
		const { config, response } = error;
		const originalRequest = config;

		// Check if response status is 403 (Forbidden)
		if (response && response.status === 403 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				// Perform refresh request using refresh token
				const refreshResponse = await axios.post('auth/refresh');
				// Update the access token in the Axios instance
				const newAccessToken = refreshResponse.data.accessToken;
				const user = refreshResponse.data.user;
				localStorage.setItem('user', JSON.stringify(user));
				localStorage.setItem('token', newAccessToken);
				originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
				// Retry the original request with the new access token
				return axios(originalRequest);
			} catch (error) {
				console.error('Error refreshing token:', error);
				logout();
			}
		}

		// For other error responses, reject with the original error
		return Promise.reject(error);
	}
);

export const logout = async () => {
	try {
		let fcmToken = -1;
		if (localStorage.getItem('fcmToken')) {
			fcmToken = localStorage.getItem('fcmToken');
		}
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		localStorage.removeItem('fcmToken');
		await axios.delete(`auth/logout/${fcmToken}`);
		
		window.location.reload();
	} catch (err) {
		console.error(err);
	}
};
export default axios;
