import { useState } from 'react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ForgotPassword from './ForgotPassword';

const LoginRegisterPage = () => {
	const [isLogin, setIsLogin] = useState(true);
	const [forgotPassword, setForgotPassword] = useState(false);
	//TODO change href to an actual website and make sure copyright is good!!!
	const Copyright = (props) => {
		return (
			<Typography
				variant='body2'
				color='text.secondary'
				align='center'
				{...props}>
				{'Copyright © '}
				<Link color='inherit' href='https://example.com/'>
					Fountain Futures LLC
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
		);
	};

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
					backgroundRepeat: 'no-repeat',
					backgroundColor: (t) =>
						t.palette.mode === 'light'
							? t.palette.grey[50]
							: t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				{forgotPassword ? (
					<ForgotPassword
						Copyright={Copyright}
						setIsLogin={setIsLogin}
						setForgotPassword={setForgotPassword}
					/>
				) : isLogin ? (
					<LoginForm
						Copyright={Copyright}
						setIsLogin={setIsLogin}
						setForgotPassword={setForgotPassword}
					/>
				) : (
					<RegisterForm Copyright={Copyright} setIsLogin={setIsLogin} />
				)}
			</Grid>
		</Grid>
	);
};

export default LoginRegisterPage;
