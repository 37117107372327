import { useState, useEffect } from 'react';
import { useAppContext } from '../../../../context/AppContext.jsx';
import axios from '../../../../services/auth-header.js';
import AddRestaurantModal from './AddRestaurantModal.jsx';
import RestaurantCard from './RestaurantCard.jsx';
import Spinner from '../../../common/spinner/spinner.jsx';
import {
	Button,
	Grid,
	Stack,
	TextField,
	Card,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Restaurant } from '../../../../models/Restaurant.js';

const ManageRestaurants = () => {
	const { user } = useAppContext();
	const [addRestaurantOpen, setAddRestaurantOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [restaurants, setRestaurants] = useState([]);
	const handleOpen = () => setAddRestaurantOpen(true);
	const handleClose = () => setAddRestaurantOpen(false);
	const [searchTerm, setSearchTermRestaurant] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		getRestaurants();
	}, []);

	const getRestaurants = async () => {
		try {
			const response = await axios.get(`restaurant/manager/${user.userId}`);
			if (response.data) {
				setRestaurants(response.data);
				console.log(response.data);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const addRestaurant = async (
		restaurantName,
		streetAddress,
		city,
		stateAbbr,
		zipCode,
		logoPath
	) => {
		const restaurant = new Restaurant(
			restaurantName,
			user.userId,
			streetAddress,
			city,
			stateAbbr,
			zipCode,
			logoPath
		);
		try {
			//TODO add better error handling here
			await axios.post('restaurant/add', restaurant);
			getRestaurants();
		} catch (error) {
			console.error(error);
		}
	};

	const handleDelete = async () => {
		getRestaurants();
	};

	const filteredRestaurants = restaurants.filter(
		(restaurant) =>
			restaurant.restaurantName
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			restaurant.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
			restaurant.streetAddress
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			restaurant.stateAbbreviation
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			restaurant.zipCode.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div className='flex flex-col py-1 flex-grow min-h-0'>
			<Stack
				direction='row'
				sx={{
					alignContent: 'center',
					justifyContent: 'center',
					marginBottom: '2%',
				}}>
				<Typography sx={{ alignContent: 'center' }} variant='h4'>
					Restaurants
				</Typography>
				<Card
					sx={{
						marginLeft: 'auto',
						width: '15rem',
					}}>
					<TextField
						label='Filter'
						variant='outlined'
						value={searchTerm}
						onChange={(e) => setSearchTermRestaurant(e.target.value)}
						fullWidth
					/>
				</Card>{' '}
				<Button
					variant='contained'
					sx={{
						minWidth: '5rem',
						maxHeight: '5rem',
						marginLeft: '2rem',
					}}
					onClick={handleOpen}>
					Create Restaurant
				</Button>
			</Stack>

			{loading ? (
				<Spinner />
			) : (
				<Grid
					container
					spacing={1}
					sx={{ rowGap: '20px' }}
					className='overflow-y-scroll pt-4 pb-16'>
					{filteredRestaurants.map((restaurant) => (
						<Grid item xs={10} md={4} sm={4} key={restaurant.restaurantId}>
							<RestaurantCard restaurant={restaurant} onDelete={handleDelete} />
						</Grid>
					))}
				</Grid>
			)}
			{/* <IconButton onClick={handleOpen}>
				<AddCircleIcon />
			</IconButton> */}
			<AddRestaurantModal
				open={addRestaurantOpen}
				onClose={handleClose}
				addRestaurant={addRestaurant}
			/>
		</div>
	);
};

export default ManageRestaurants;
