import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import axios from '../../services/auth-header.js';
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = ({ Copyright, token }) => {
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post('auth/password', {
				token,
				password,
			});
			if (response.data) {
				navigate('/');
			} else {
				setError('Password reset has expired. Please try again later.');
			}
		} catch (error) {
			if (typeof error?.response?.data === 'string') {
				setError(error?.response?.data);
			} else {
				setError('Unable to reset password. Please try again.');
			}
			console.error(error);
		}
	};

	return (
		<Box
			sx={{
				my: 8,
				mx: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component='h1' variant='h5'>
				Reset Password
			</Typography>
			<Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
				<FormControl required fullWidth>
					<InputLabel htmlFor='password'>Password</InputLabel>
					<OutlinedInput
						id='outlined-adornment-password'
						type={showPassword ? 'text' : 'password'}
						onChange={(e) => {
							if (error !== '') {
								setError('');
							}
							setPassword(e.target.value);
						}}
						autoComplete='password'
						name='password'
						value={password}
						inputProps={{ maxLength: '22' }}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={(e) => e.preventDefault()}
									edge='end'>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label='Password'
					/>
					{!!error && <FormHelperText error>{error}</FormHelperText>}
				</FormControl>

				<Button
					type='submit'
					fullWidth
					variant='contained'
					sx={{ mt: 3, mb: 2 }}>
					Reset Password
				</Button>
				<Copyright sx={{ mt: 5 }} />
			</Box>
		</Box>
	);
};
export default ResetPasswordForm;
