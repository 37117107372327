import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import axios from '../../services/auth-header.js';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
} from '@mui/material';

const ForgotPassword = ({ Copyright, setIsLogin, setForgotPassword }) => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post('auth/reset', {
				email,
			});
			if (response.data) {
				setError('');
				setSuccess(true);
			}
		} catch (error) {
			setError('No account found with that email. Please try again.');
			console.error(error);
		}
	};

	return (
		<Box
			sx={{
				my: 8,
				mx: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component='h1' variant='h5'>
				Forgot Password
			</Typography>
			<Box
				component='form'
				onSubmit={handleSubmit}
				sx={{ mt: 1, width: '100%' }}>
				<FormControl margin='normal' required fullWidth>
					<InputLabel htmlFor='email'>Email</InputLabel>
					<OutlinedInput
						autoFocus
						type={'email'}
						onChange={(e) => {
							if (error !== '') {
								setError('');
							}
							if (success) {
								setSuccess(false);
							}
							setEmail(e.target.value);
						}}
						autoComplete='email'
						name='email'
						value={email}
						inputProps={{ maxLength: '100' }}
						label='Email'
					/>
					{!!error && <FormHelperText error>{error}</FormHelperText>}
					{success && (
						<FormHelperText>
							Password reset email sent successfully
						</FormHelperText>
					)}
				</FormControl>
				<Button
					type='submit'
					fullWidth
					variant='contained'
					sx={{ mt: 3, mb: 2 }}>
					Reset Password
				</Button>
				<Grid container>
					<Grid xs={12} item>
						<Grid xs={12} item>
							<Link
								href='#'
								onClick={() => {
									setIsLogin(false);
									setForgotPassword(false);
								}}
								variant='body2'>
								{"Don't have an account? Register"}
							</Link>
						</Grid>
					</Grid>
					<Grid xs={12} item>
						<Link
							href='#'
							onClick={() => setForgotPassword(false)}
							variant='body2'>
							{'Return to login'}
						</Link>
					</Grid>
				</Grid>
				<Copyright sx={{ mt: 5 }} />
			</Box>
		</Box>
	);
};
export default ForgotPassword;
