import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Select,
	MenuItem,
} from '@mui/material';
import { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from '../../../common/VisuallyHiddenInput';
import axios from '../../../../services/auth-header.js';
import Link from '@mui/material/Link';

const bucketUrl = process.env.REACT_APP_S3_BUCKET_URL;

const AddRestaurantModal = ({ open, onClose, addRestaurant }) => {
	const [restaurantName, setRestaurantName] = useState('');
	const [streetAddress, setStreetAddress] = useState('');
	const [city, setCity] = useState('');
	const [stateAbbr, setStateAbbr] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [logoPath, setLogoPath] = useState(null);

	const createAndClose = () => {
		addRestaurant(
			restaurantName,
			streetAddress,
			city,
			stateAbbr,
			zipCode,
			logoPath
		);
		onClose();
	};

	const imageUploaded = async (e) => {
		console.log('Bucket URL: ' + bucketUrl);
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('image', file);
		try {
			const response = await axios.post('image/create', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const newLogoPath = response.data.imagePath;

			setLogoPath(newLogoPath);

			console.log('Image path: ' + newLogoPath);
		} catch (err) {
			console.error(err);
		}
	};

	const stateAbbreviations = [
		'AL',
		'AK',
		'AZ',
		'AR',
		'CA',
		'CO',
		'CT',
		'DE',
		'FL',
		'GA',
		'HI',
		'ID',
		'IL',
		'IN',
		'IA',
		'KS',
		'KY',
		'LA',
		'ME',
		'MD',
		'MA',
		'MI',
		'MN',
		'MS',
		'MO',
		'MT',
		'NE',
		'NV',
		'NH',
		'NJ',
		'NM',
		'NY',
		'NC',
		'ND',
		'OH',
		'OK',
		'OR',
		'PA',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VT',
		'VA',
		'WA',
		'WV',
		'WI',
		'WY',
	];
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Add Restaurant</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin='dense'
					id='name'
					name='name'
					label='Restaurant Name'
					fullWidth
					variant='standard'
					value={restaurantName}
					onChange={(e) => setRestaurantName(e.target.value)}
				/>
				<TextField
					autoFocus
					margin='dense'
					id='street'
					name='street'
					label='Street Address'
					fullWidth
					variant='standard'
					value={streetAddress}
					onChange={(e) => setStreetAddress(e.target.value)}
				/>
				<TextField
					autoFocus
					margin='dense'
					id='city'
					name='city'
					label='City'
					fullWidth
					variant='standard'
					value={city}
					onChange={(e) => setCity(e.target.value)}
				/>
				<Select
					id='stateSelect'
					name='state'
					label='State'
					fullWidth
					sx={{ height: '60px' }}
					variant='standard'
					value={stateAbbr}
					onChange={(e) => setStateAbbr(e.target.value)}
				>
					{stateAbbreviations.map((stateAbbr, index) => (
						<MenuItem key={index} value={stateAbbr}>
							{stateAbbr}
						</MenuItem>
					))}
				</Select>
				<TextField
					autoFocus
					margin='dense'
					id='zipCode'
					name='zipCode'
					label='Zip Code'
					fullWidth
					variant='standard'
					value={zipCode}
					onChange={(e) => setZipCode(e.target.value)}
				/>
				<div className='flex flex-col text-center'>
					{logoPath && (
						<Link
							href={bucketUrl + logoPath}
							target='_blank'
							rel='noreferrer'
							className='h-8'
						>
							{logoPath ? 'logo.png' : ''}
						</Link>
					)}
					<Button
						component='label'
						role={undefined}
						variant='contained'
						tabIndex={-1}
						startIcon={<CloudUploadIcon />}
						className='mt-2'
					>
						Upload Logo
						<VisuallyHiddenInput
							type='file'
							accept='image/*'
							onChange={imageUploaded}
						/>
					</Button>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={createAndClose} variant='contained' type='submit'>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddRestaurantModal;
