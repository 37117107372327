import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const ContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState(null);
	const openToast = (message, type) => {
		setToast({ message: message, type: type });
		setShowToast(true);
	};
	const closeToast = () => {
		setShowToast(false);
		setTimeout(() => setToast(null), 1000);
	};
	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				showToast,
				setShowToast,
				openToast,
				closeToast,
				toast,
				setToast,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	const context = useContext(AppContext);
	return context || null;
};
