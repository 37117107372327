export class Restaurant {
    restaurantId;
    restaurantName;
    managerId;
    streetAddress;
    city;
    stateAbbreviation;
    zipCode;
    logoPath;
    constructor(restaurantName, managerId, streetAddress, city, stateAbbr, zipCode, logoPath) {
        this.restaurantName = restaurantName;
        this.managerId = managerId;
        this.streetAddress = streetAddress;
        this.city = city;
        this.stateAbbreviation = stateAbbr;
        this.zipCode = zipCode;
        this.logoPath = logoPath;
    }
}