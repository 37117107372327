import { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Stack,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormHelperText,
} from '@mui/material';
import { Group } from '../../../../models/Group';
import { useAppContext } from '../../../../context/AppContext';
import axios from '../../../../services/auth-header.js';
const UpsertGroupModal = ({
	open,
	onClose,
	refreshGroups,
	existingGroup = null,
}) => {
	const { user } = useAppContext();
	const [groupName, setGroupName] = useState(existingGroup?.groupName || '');
	const [error, setError] = useState(null);

	useEffect(() => {
		if (error) {
			setError(null);
		}
	}, [groupName]);

	const upsertGroup = async () => {
		if (existingGroup?.groupName === groupName) {
			onClose();
			return;
		}
		let currentGroup = new Group(null, groupName, user.userId);

		if (existingGroup) {
			currentGroup = existingGroup;
			currentGroup.groupName = groupName;
		}

		try {
			const response = await axios.post(
				`group/${currentGroup.groupId ? 'save' : 'create'}`,
				currentGroup
			);
			if (response.data) {
				onClose();
				refreshGroups();
			}
		} catch (error) {
			console.error(error);
			if (error?.response?.data?.errors) {
				if (
					error?.response?.data?.errors[0]?.message ===
					'GROUP_NAME must be unique'
				) {
					setError('Sorry, you already have a group with that name.');
				}
			}
		}
	};

	const deleteGroup = async () => {
		try {
			const response = await axios.post('group/delete', {
				groupId: existingGroup.groupId,
			});
			if (response.data) {
				onClose();
				refreshGroups();
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Dialog
			maxWidth='xs'
			fullWidth
			sx={{ p: 16 }}
			open={open}
			onClose={onClose}>
			<DialogTitle>{existingGroup ? 'Update' : 'Add'} Group</DialogTitle>
			<DialogContent>
				<TextField
					label='Group Name'
					value={groupName}
					fullWidth
					margin='dense'
					variant='standard'
					onChange={(e) => setGroupName(e.target.value)}
				/>
				{!!error && <FormHelperText error>{error}</FormHelperText>}
			</DialogContent>
			<DialogActions>
				{existingGroup && (
					<Button color='error' onClick={deleteGroup}>
						Delete Group
					</Button>
				)}
				<Button disabled={groupName === '' || error} onClick={upsertGroup}>
					{existingGroup ? 'Update' : 'Add'} Group
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UpsertGroupModal;
