export class Diner {
	userId;
	firstName;
	lastName;
	email;
	zipCode;
	gender;
	updatedDate;
	constructor(userId, firstName, lastName, email, zipCode, gender) {
		this.userId = userId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.zipCode = zipCode;
        this.gender = gender;
		this.updatedDate = new Date();
	}
}