import { Select, MenuItem, Box } from '@mui/material';
import { QuestionBranch as Branch } from '../../models/QuestionBranch.js';
import { useState, useEffect } from 'react';
import axios from '../../services/auth-header.js';

const QuestionBranch = ({ question, questions, numberResponse, getSurvey }) => {
	const [selectedQuestionId, setSelectedQuestionId] = useState('null');

	useEffect(() => {
		if (question.questionBranches) {
			const matchingBranch = question.questionBranches.find(
				(branch) => branch.numberResponse === numberResponse
			);

			if (matchingBranch) {
				setSelectedQuestionId(matchingBranch.conditionalQuestionId);
			}
		}
	}, [question.questionBranches, numberResponse]);

	const handleQuestionBranch = async (questionId) => {
		try {
			const branch = new Branch(
				question.questionId,
				questionId,
				numberResponse
			);
			if (questionId !== 'null') {
				await axios.post('question/branch/create', branch);
			} else {
				await axios.delete(
					`question/branch/delete/${question.questionId}/${selectedQuestionId}`
				);
			}
			setSelectedQuestionId(questionId);
			getSurvey();
		} catch (error) {
			console.log(error);
		}
	};

	const filteredQuestions = questions.filter(
		(q) => q.questionIndex > question.questionIndex
	);

	return (
		<Box sx={{ width: '100%', maxWidth: 240 }}>
			<Select
				fullWidth
				value={selectedQuestionId}
				onChange={(event) => handleQuestionBranch(event.target.value)}
			>
				<MenuItem value='null'>Continue to next question</MenuItem>
				{filteredQuestions.map((q) => (
					<MenuItem key={q.questionId} value={q.questionId}>
						{q.questionText}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default QuestionBranch;
