import { Alert, Slide } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import { useEffect, useState } from 'react';

const PopToast = () => {
	const { toast, showToast, closeToast } = useAppContext();
	const [closing, setClosing] = useState(null);
	const startTimer = () => {
		setClosing(
			setTimeout(() => {
				closeToast();
			}, 2000)
		);
	};
	const cancelTimer = () => {
		if (closing) {
			clearTimeout(closing);
		}
	};
	useEffect(() => {
		if (showToast) {
			startTimer();
		} else {
			setClosing(null);
		}
	}, [showToast]);
	return (
		<div className='sm:w-1/3 h-fit fixed top-4 left-1/2 -translate-x-1/2 z-[1500]'>
			<Slide direction='right' in={showToast}>
				<Alert
					severity={toast?.type}
					onClose={closeToast}
					onMouseEnter={cancelTimer}
					onMouseLeave={startTimer}>
					{toast?.message}
				</Alert>
			</Slide>
		</div>
	);
};

export default PopToast;
