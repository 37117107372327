import { HashRouter, Route, Routes } from 'react-router-dom';
import ResetPassword from '../userAuth/ResetPassword.jsx';
import LoginRegisterPage from '../userAuth/LoginRegisterPage.jsx';
const AuthRouter = () => {
	return (
		<HashRouter>
			<Routes>
				<Route path='/reset/password/:token' element={<ResetPassword />} />
				<Route path='*' element={<LoginRegisterPage />} />
			</Routes>
		</HashRouter>
	);
};

export default AuthRouter;
