import {
	Button,
	Card,
	CardContent,
	Container,
	Stack,
	Avatar,
} from '@mui/material';
import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InviteCard = ({ invitation, openDenyModal }) => {
	const [survey, setSurvey] = useState(null);
	const [restaurant, setRestaurant] = useState(null);
	const [logoPath, setLogoPath] = useState('');
	const navigate = useNavigate();
	const createSurveyResponse = async () => {
		try {
			const surveyResponse = {
				surveyId: invitation.surveyId,
				dinerId: invitation.dinerId,
				restaurantId: invitation.restaurantId,
				expirationDate: invitation.expirationDate,
			};
			const result = await axios.post(
				`/survey/response/create`,
				surveyResponse
			);
			// console.log("Response for creating survey:\n" + JSON.stringify(result));
			if (result) {
				navigate(`/survey/respond`);
			}
		} catch (error) {
			console.error('ERROR CREATING SURVEY RESPONSE:\n' + error);
		}
	};

	const accept = async () => {
		try {
			const result = await axios.post(
				`/survey/invite/accept/${invitation.surveyInvitationId}`
			);
			if (result) {
				await createSurveyResponse();
			}
		} catch (error) {
			console.log('ERROR ACCEPTING SURVEY:\n' + error);
		}
	};

	const getSurvey = async () => {
		try {
			const result = await axios.get(`survey/${invitation.surveyId}`);
			const surveyResponse = result.data;
			if (!surveyResponse) {
				setSurvey(null);
			} else {
				setSurvey(result.data);
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	const getRestaurant = async () => {
		try {
			const result = await axios.get(`restaurant/${invitation.restaurantId}`);
			const restaurantResponse = result.data;
			console.log(restaurantResponse);
			if (!restaurantResponse) {
				setRestaurant(null);
			} else {
				setRestaurant(result.data);
				setLogoPath(
					process.env.REACT_APP_S3_BUCKET_URL + restaurantResponse.logoPath
				);
			}
		} catch (error) {
			console.error('ERROR FETCHING RESTAURANT:\n' + error);
		}
	};

	useEffect(() => {
		getRestaurant();
		getSurvey();
	}, []);

	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible'
			style={{ maxWidth: '32rem' }}>
			<CardContent>
				<Container>
					<Stack alignItems={'center'} spacing={2}>
						{restaurant && (
							<Avatar
								alt={`${restaurant.restaurantName} Logo`}
								src={process.env.REACT_APP_S3_BUCKET_URL + restaurant?.logoPath}
								sx={{ width: 80, height: 80 }}
							/>
						)}
						{/* <div className='font-bold'>
							{survey ? survey.surveyTitle : 'NO TITLE'}
						</div> */}
						<div className='font-bold'>
							{restaurant && restaurant.restaurantName}
						</div>
						<div className='italic'>
							{restaurant &&
								`${restaurant.city}, ${restaurant.stateAbbreviation}`}
						</div>
						<div className='w-full flex justify-center space-x-12'>
							<Button
								className='px-8'
								variant='outlined'
								onClick={() => openDenyModal(invitation.surveyInvitationId)}>
								Deny
							</Button>
							<Button className='px-6' variant='contained' onClick={accept}>
								Accept
							</Button>
						</div>
					</Stack>
				</Container>
			</CardContent>
		</Card>
	);
};

export default InviteCard;
