import { Stack, TextField, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext.jsx';
import axios from '../../../services/auth-header.js';
import debounce from '../../../utils/debounce.js';
import CameraIcon from '@mui/icons-material/Camera';
import Link from '@mui/material/Link';
import { VisuallyHiddenInput } from '../../common/VisuallyHiddenInput.jsx';

const bucketUrl = process.env.REACT_APP_S3_BUCKET_URL;

const PictureResponse = ({ question }) => {
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [isCreate, setIsCreate] = useState(true);
	const [imagePath, setImagePath] = useState(
		question.questionResponses?.[0]?.imagePath || 0
	);

	const { user } = useAppContext();

	useEffect(() => {
		setIsCreate(user.admin);
	}, []);

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const photoCaptured = async (e) => {
		console.log('Bucket URL: ' + bucketUrl);
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('image', file);
		try {
			const response = await axios.post('image/create', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const newImagePath = response.data.imagePath;

			setImagePath(newImagePath);

			question.imagePath = newImagePath;

			console.log('Image path: ' + newImagePath);
			await axios.post('question/response/save', {
				...question,
				imagePath: newImagePath,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionResponseId,
			});
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Stack alignItems={'center'} spacing={2}>
			{isCreate ? (
				<TextField
					className='question-text'
					value={questionText}
					onChange={handleQuestionTextChange}
					variant='outlined'
					fullWidth
					multiline
					placeholder={'Enter your question here'}
				/>
			) : (
				<Typography variant='subtitle1' component='legend'>
					{question.questionText}
				</Typography>
			)}
			<Link
				href={bucketUrl + imagePath}
				target='_blank'
				rel='noreferrer'
				className='h-8'
			>
				{imagePath ? 'capture.png' : ''}
			</Link>
			<Button
				component='label'
				role={undefined}
				variant='contained'
				tabIndex={-1}
				startIcon={<CameraIcon />}
			>
				Camera
				<VisuallyHiddenInput
					type='file'
					accept='image/*'
					capture='environment'
					onChange={photoCaptured}
				/>
			</Button>
		</Stack>
	);
};

export default PictureResponse;
