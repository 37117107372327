import React from 'react'
import './spinner.css'

const Spinner = () => {
	return (
		<div className='w-full h-full flex flex-col justify-center'>
			<div className='mx-auto'>
				<div className='lds-roller'>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div>
					<div className='pt-6 text-base dots'>
						<span>L</span>
						<span>o</span>
						<span>a</span>
						<span>d</span>
						<span>i</span>
						<span>n</span>
						<span>g</span>
						<span>.</span>
						<span>.</span>
						<span>.</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Spinner
