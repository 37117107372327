import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useState } from 'react';

const CreateSurveyModal = ({ open, onClose, createSurvey }) => {
	const [surveyTitle, setSurveyTitle] = useState('');
	const [surveyIntro, setSurveyIntro] = useState('');

	const createAndClose = () => {
		createSurvey(surveyTitle, surveyIntro);
		onClose();
	};
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Create Survey</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin='dense'
					id='title'
					name='title'
					label='Survey Title'
					fullWidth
					variant='standard'
					value={surveyTitle}
					onChange={(e) => setSurveyTitle(e.target.value)}
				/>
				<TextField
					autoFocus
					margin='dense'
					id='intro'
					name='intro'
					label='Survey Introduction'
					fullWidth
					variant='standard'
					value={surveyIntro}
					onChange={(e) => setSurveyIntro(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={createAndClose} variant='contained' type='submit'>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateSurveyModal;
