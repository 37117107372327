import {
	Card,
	CardContent,
	CardMedia,
	Container,
	IconButton,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../services/auth-header';

const RestaurantCard = ({ restaurant, onDelete }) => {
	const [openDialog, setOpenDialog] = useState(false);
	const logoUrl = restaurant.logoPath
		? process.env.REACT_APP_S3_BUCKET_URL + restaurant.logoPath
		: null;

	const navigate = useNavigate();
	const onClick = () => {
		navigate(`/admin/restaurant/${restaurant.restaurantId}`);
	};
	const handleDelete = async (event) => {
		event.stopPropagation();
		setOpenDialog(true);
	};

	const handleDeleteConfirmed = async () => {
		try {
			await axios.delete(`restaurant/delete/${restaurant.restaurantId}`);
			onDelete();
		} catch (err) {
			console.error(err);
		}
		setOpenDialog(false);
	};

	const handleCancelDelete = () => {
		setOpenDialog(false);
	};

	return (
		<>
			<Card
				variant='outlined'
				className='overflow-visible mx-8 cursor-pointer relative'
				onClick={onClick}
				style={{
					width: '90%',
					minHeight: '20rem',
					cursor: 'pointer',
					boxShadow:
						'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
				}}>
				{logoUrl && <CardMedia sx={{ height: 100 }} image={logoUrl} />}
				<CardContent>
					<div>
						<Container>
							<b>Restaurant Name:</b> {restaurant.restaurantName}
						</Container>
						<Container>
							<b>Street Address:</b> {restaurant.streetAddress}
						</Container>
						<Container>
							<b>City:</b> {restaurant.city}
						</Container>
						<Container>
							<b>State:</b> {restaurant.stateAbbreviation}
						</Container>
						<Container>
							<b>Zip Code:</b> {restaurant.zipCode}
						</Container>
						<div className='absolute bottom-2 right-2'>
							<IconButton onClick={handleDelete}>
								<DeleteIcon />
							</IconButton>
						</div>
					</div>
				</CardContent>
			</Card>
			<Dialog open={openDialog} onClose={handleCancelDelete}>
				<DialogTitle>Delete Restaurant</DialogTitle>
				<DialogContent>
					<p>
						Are you sure you want to delete this restaurant? This action cannot
						be undone.
					</p>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCancelDelete}
						variant='contained'
						color='primary'>
						Cancel
					</Button>
					<Button
						onClick={handleDeleteConfirmed}
						variant='contained'
						color='error'>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default RestaurantCard;
