import {
	Card,
	CardContent,
	Typography,
	Stack,
	Box,
	Paper,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from '../../../../services/auth-header.js';
import UneditableSurvey from './UneditableSurvey.jsx';

const SurveyResponsesView = () => {
	const { recurringSurveyId } = useParams();
	const [data, setData] = useState([]);
	const [survey, setSurvey] = useState([]);
	const [responses, setResponses] = useState([]);
	const [restaurant, setRestaurant] = useState([]);
	const [selectedResponse, setSelectedResponse] = useState(null);
	const [logoUrl, setLogoUrl] = useState(null);

	useEffect(() => {
		getSurveyData();
	}, []);

	const getSurveyData = async () => {
		try {
			const response = await axios.get(
				`survey/response/data/recurring/${recurringSurveyId}`
			);
			const { survey, restaurant } = response.data;
			setSurvey(survey);
			setRestaurant(restaurant);
			setLogoUrl(
				restaurant.logoPath
					? process.env.REACT_APP_S3_BUCKET_URL + restaurant.logoPath
					: null
			);
			setData(response.data);
			if (Array.isArray(survey.surveyResponses)) {
				setResponses(survey.surveyResponses);
				setSelectedResponse(survey.surveyResponses[0]);
			} else {
				setResponses([]);
			}
			// console.log(response);
		} catch (error) {
			console.error('Error fetching survey data:', error);
		}
	};

	const handleResponseClick = (response) => {
		// console.log(response);
		setSelectedResponse(response);
	};

	return (
		<Stack
			direction='row'
			spacing={2}
			style={{ height: '100%', flexGrow: 1, margin: '20px' }}
		>
			{responses.length > 0 ? (
				<Stack
					flexGrow={1}
					spacing={2}
					style={{ maxHeight: '90%', overflowY: 'auto', flexGrow: 1 }}
				>
					{' '}
					<Paper elevation={2}>
						<Stack spacing={2}>
							{responses.map((response, index) => (
								<Card
									variant='outlined'
									key={index}
									onClick={() => handleResponseClick(response)}
									style={{
										// margin: '10px',
										cursor: 'pointer',
										backgroundColor:
											selectedResponse === response ? '#f0f0f0' : 'white',
										boxShadow:
											'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
										transition: 'background-color 0.3s ease',
									}}
								>
									<CardContent>
										<Typography variant='h5'>
											{/* <b>Response {index + 1}</b> */}
										</Typography>
										<Typography>
											<b>Diner Name:</b> {response.diner.firstName}{' '}
											{response.diner.lastName} <br />
											<b>Diner Email:</b> {response.diner.email} <br />
											<b>Diner ZipCode:</b> {response.diner.zipCode.zipCode}{' '}
											<br />
											<b>Response Status:</b>{' '}
											{response.submitted ? 'Completed' : 'In Progress'}
										</Typography>
									</CardContent>
								</Card>
							))}
						</Stack>
					</Paper>
				</Stack>
			) : (
				<p>No responses available</p>
			)}
			<Paper
				elevation={2}
				style={{
					maxHeight: '90%',
					overflowY: 'auto',
					flexGrow: 2,
					// margin: '10px',
					width: '80%'
				}}
			>
				<Stack spacing={2}>
					<Card
						style={{
							width: '80%',
							cursor: 'pointer',
							// backgroundColor:
							// 	selectedResponse === response ? '#f0f0f0' : 'white',
							boxShadow:
								'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
							marginLeft: '10%',
							marginRight: '10%',
							marginTop: '5%',
						}}
					>
						<CardContent>
							<Stack
								direction='row'
								spacing={2}
								alignContent={'center'}
								justifyContent={'center'}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<img
										src={logoUrl}
										alt='Restaurant Logo'
										style={{
											maxWidth: '100%',
											maxHeight: '100px', // Adjust the height as needed
										}}
									/>
								</div>
								<Typography variant='h6' flexGrow={1} align='center'>
									<b>Restaurant Location:</b> {restaurant.restaurantName} -{' '}
									{restaurant.city}
									<br />
									<b>Survey Title:</b> {survey.surveyTitle}
									<br />
									<b>Survey Description:</b> {survey.surveyIntro}
									<br />
									<b>Active Survey:</b> {data.active ? 'Active' : 'Inactive'}
								</Typography>
							</Stack>
						</CardContent>
					</Card>
					<UneditableSurvey surveyResponse={selectedResponse} />
				</Stack>
			</Paper>
		</Stack>
	);
};

export default SurveyResponsesView;
