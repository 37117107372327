import React, { useState, useEffect } from 'react';
import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { useAppContext } from '../../../../../context/AppContext';
import axios from '../../../../../services/auth-header.js';
import Autocomplete from '@mui/material/Autocomplete';

const SelectRestaurant = ({
	selectedRestaurants,
	setSelectedRestaurants,
	resetSignal,
}) => {
	const { user } = useAppContext();
	const [restaurants, setRestaurants] = useState([]);
	const [groups, setGroups] = useState([]);
	const [autocompleteKey, setAutocompleteKey] = useState(0);

	useEffect(() => {
		getRestaurants();
		getGroups();
	}, []);

	useEffect(() => {
		if (resetSignal) {
			setSelectedRestaurants([]);
			setAutocompleteKey((prevKey) => prevKey + 1);
		}
		getRestaurants();
	}, [resetSignal]);

	const getRestaurants = async () => {
		try {
			const response = await axios.get(
				`restaurant/manager/no-survey/${user.userId}`
			);
			if (response.data) {
				setRestaurants(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getGroups = async () => {
		try {
			const response = await axios.get(`group/${user.userId}`);
			if (response.data) {
				setGroups(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleRestaurantChange = (event, newValue) => {
		setSelectedRestaurants(newValue);
	};

	return (
		<Card
			sx={{
				flex: '1 1 auto',
				maxWidth: '30%',
				minHeight: '100%',
				boxShadow:
					'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
			}}>
			<CardContent align='center'>
				<Stack spacing={4}>
					<Typography variant='h6'>Step 3: Select Your Restaurants</Typography>
					<Typography variant='body1'>
						We will send out the desired number of surveys at your desired
						frequency for each of these restaurants.
					</Typography>
					<Autocomplete
						key={autocompleteKey} // Key prop to reset Autocomplete
						multiple
						options={groups.concat(restaurants)}
						getOptionLabel={(option) => {
							return option?.restaurantName
								? `${option?.restaurantName} - ${option.city}`
								: option?.groupName;
						}}
						groupBy={(option) => (option.groupName ? 'Groups' : 'Restaurants')}
						renderInput={(params) => (
							<TextField {...params} label='Select Restaurants' />
						)}
						onChange={handleRestaurantChange}
					/>
					<Typography variant='body1'>
						If you don't see any of your restaurants, then you either don't have
						restaurants or all your restaurants have surveys running. Please add
						new restaurants or end the surveys currently running on your
						restaurants.
					</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default SelectRestaurant;
