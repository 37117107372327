//the challenge is a crucial part of the authentication process,
//and is used to mitigate "replay attacks" and allow server-side authentication
//in a real app, you'll want to generate the challenge server-side and
//maintain a session or temporary record of this challenge in your DB
import axios from './auth-header.js';
const generateRandomChallenge = () => {
	let length = 32;
	let randomValues = new Uint8Array(length);
	window.crypto.getRandomValues(randomValues);
	return randomValues;
};

export const createPasskey = async (user) => {
	if (
		!navigator.credentials ||
		!navigator.credentials.create ||
		!navigator.credentials.get
	) {
		return alert('Your browser does not support the Web Authentication API');
	}

	let credentials = await navigator.credentials.create({
		publicKey: {
			challenge: generateRandomChallenge(),
			rp: { name: 'Secret Diner', id: window.location.hostname },
			user: {
				id: new Uint8Array(16),
				name: user.email,
				//TODO remove the || once we start collecting first and last name
				displayName: `${user.firstName || 'first'} ${user.lastName || 'last'}`,
			},
			pubKeyCredParams: [
				{ type: 'public-key', alg: -7 },
				{ type: 'public-key', alg: -257 },
			],
			timeout: 60000,
			authenticatorSelection: {
				residentKey: 'preferred',
				requireResidentKey: false,
				userVerification: 'preferred',
			},
			attestation: 'none',
			extensions: { credProps: true },
		},
	});
	const response = await axios.post('auth/passkey/register', {
		userId: user.userId,
		passkey: JSON.stringify(credentials.id),
	});
	if (response.data) {
		localStorage.setItem('preferBiometricAuthentication', 'true');
	}
	return response.data || false;
};

export const verifyPasskey = async () => {
	try {
		let credentials = await navigator.credentials.get({
			publicKey: {
				challenge: generateRandomChallenge(),
				rp: { name: 'Secret Diner', id: window.location.hostname },
				user: {},
				pubKeyCredParams: [
					{ type: 'public-key', alg: -7 },
					{ type: 'public-key', alg: -257 },
				],
				timeout: 60000,
				authenticatorSelection: {
					residentKey: 'preferred',
					requireResidentKey: false,
					userVerification: 'preferred',
				},
				attestation: 'none',
			},
		});
		const response = await axios.post('auth/passkey/login', {
			passkey: JSON.stringify(credentials.id),
		});
		return response.data || false;
	} catch (err) {
		console.error(err);
	}
};
