export class Question {
	questionId;
	questionType;
	surveyId;
	questionText;
	questionIndex;
	conditionalQuestion;
	createdDate;
	updatedDate;
	constructor(surveyId, questionType, questionIndex) {
		this.surveyId = surveyId;
		this.questionType = questionType;
		this.questionIndex = questionIndex;
		this.conditionalQuestion = false;
		this.createdDate = new Date();
		this.updatedDate = new Date();
	}
}
