import { useEffect } from 'react';
import { useState } from 'react';
import axios from '../../../../../services/auth-header.js';
import {
	Card,
	CardContent,
	Typography,
	Autocomplete,
	TextField,
	Stack,
} from '@mui/material';
import { useAppContext } from '../../../../../context/AppContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const SelectTemplate = ({
	updateSurveyId,
	surveyStartDate,
	setSurveyStartDate,
}) => {
	const { user } = useAppContext();
	const [surveys, setSurveys] = useState([]);
	const [selectedSurvey, setSelectedSurvey] = useState('');
	useEffect(() => {
		getSurveys();
	}, []);

	const getSurveys = async () => {
		try {
			const response = await axios.get(`survey/owner/${user.userId}`);
			if (response.data) {
				setSurveys(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSurveyChange = (event, newValue) => {
		setSelectedSurvey(newValue);
		updateSurveyId(newValue);
	};

	return (
		<Card
			sx={{
				flex: '1 1 auto',
				maxWidth: '30%',
				minHeight: '100%',
				boxShadow:
					'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
			}}
		>
			<CardContent align='center'>
				<Stack spacing={4}>
					<Typography variant='h6'>
						Step 1: Select the Survey Template
					</Typography>{' '}
					<Typography variant='body1'>
						Make sure you have finished editing your survey. It can not be
						changed once the survey has started.
					</Typography>
					<Autocomplete
						disablePortal
						disableClearable
						options={surveys}
						getOptionLabel={(option) => (option ? option.surveyTitle : '')}
						renderInput={(params) => (
							<TextField {...params} label='Survey Template' />
						)}
						value={selectedSurvey || null}
						onChange={handleSurveyChange}
					/>
					<Typography variant='body1'>
						Select when you would like the survey to begin.
					</Typography>
					<DatePicker
						label='Survey Start Date'
						value={surveyStartDate}
						onChange={(newDate) => setSurveyStartDate(newDate)}
					/>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default SelectTemplate;
