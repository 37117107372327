import { Stack } from '@mui/material';
import QuestionResponse from '../../questions/QuestionResponse';

const UneditableSurvey = ({ surveyResponse }) => {
	return (
		<Stack
			spacing={2}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '200px',
				marginBottom: '5%'
			}}
		>
			{surveyResponse?.questionResponses.map((questionResponse) => {
				if (questionResponse.question.conditionalQuestion) {
					return null;
				}

				for (const branch of questionResponse.question.questionBranches) {
					for (const otherResponse of surveyResponse.questionResponses) {
						if (
							otherResponse.questionId === branch.conditionalQuestionId &&
							questionResponse.numberResponse === branch.numberResponse
						) {
							otherResponse.question.conditionalQuestion = false;
						}
					}
				}

				return (
					<QuestionResponse
						key={questionResponse.questionResponseId}
						questionResponse={questionResponse}
					/>
				);
			})}
		</Stack>
	);
};

export default UneditableSurvey;
