import { Button, Stack, Typography, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from '../../../services/auth-header.js';
import { useAppContext } from '../../../context/AppContext';
import debounce from '../../../utils/debounce.js';
import QuestionBranch from '../../manager/QuestionBranch.jsx';

const YesNo = ({
	question,
	questions,
	getSurvey,
	setIsHiddenQuestion,
	isHiddenBranchOption,
}) => {
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [selectedOption, setSelectedOption] = useState(null);
	const [isCreate, setIsCreate] = useState(true);
	const { user } = useAppContext();
	const [showBranchOption, setShowBranchOption] =
		useState(isHiddenBranchOption);

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const handleOptionChange = async (value) => {
		console.log(value);
		console.log(question.questionResponses[0])
		setSelectedOption(value);
		question.questionResponses[0].numberResponse = value;
		console.log(question)
		try {
			await axios.post('question/response/save', {
				...question,
				numberResponse: value,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionResponseId,
			});

			if (question.questionBranches && question.questionBranches.length > 0) {
				const matchingBranch = question.questionBranches.find(
					(branch) => branch.numberResponse === value
				);

				question.questionBranches.forEach((branch) => {
					if (branch !== matchingBranch) {
						handleConditionalQuestionBranches(
							branch.conditionalQuestionId,
							true
						);
					}
				});

				if (matchingBranch) {
					handleVisibilityChange(matchingBranch.conditionalQuestionId, false);
				}
			}
			getSurvey();
		} catch (err) {
			console.error(err);
		}
	};

	const handleConditionalQuestionBranches = async (
		conditionalQuestionId,
		isHidden
	) => {
		const conditionalQuestion = findQuestionById(conditionalQuestionId);
		await axios.post(
			`question/response/clear/${conditionalQuestion.questionId}/${user.userId}`
		);
		if (!conditionalQuestion) return;

		handleVisibilityChange(conditionalQuestionId, isHidden);

		if (
			conditionalQuestion.questionBranches &&
			conditionalQuestion.questionBranches.length > 0
		) {
			for (const branch of conditionalQuestion.questionBranches) {
				await handleConditionalQuestionBranches(
					branch.conditionalQuestionId,
					isHidden
				);
			}
		}
	};

	const findQuestionById = (questionId) => {
		for (const question of questions) {
			if (question.questionId === questionId) {
				return question;
			}
		}
		return null;
	};

	const handleVisibilityChange = (questionId, newVisibility) => {
		setIsHiddenQuestion(questionId, newVisibility);
	};

	useEffect(() => {
		// console.log(question)
		if (!user.admin) {
			if (question.questionResponses?.[0]?.numberResponse === null) {
				setSelectedOption('');
			} else {
				setSelectedOption(question.questionResponses[0].numberResponse);
			}
		}
		setIsCreate(user.admin);
		setShowBranchOption(isHiddenBranchOption);
	}, [question, user, isHiddenBranchOption, question.questionResponses]);

	return (
		<Stack alignItems={'center'} justifyContent={'center'} spacing={2}>
			{isCreate ? (
				<>
					<TextField
						value={questionText}
						onChange={handleQuestionTextChange}
						fullWidth
						placeholder='Enter your yes or no question here'
					></TextField>
					<Stack direction='row' spacing={2}>
						<Button variant='contained' color='info' size='large'>
							Yes
						</Button>
						{!showBranchOption && (
							<QuestionBranch
								question={question}
								questions={questions}
								numberResponse={1}
								getSurvey={getSurvey}
							/>
						)}
						<Button variant='contained' color='error' size='large'>
							No
						</Button>
						{!showBranchOption && (
							<QuestionBranch
								question={question}
								questions={questions}
								numberResponse={0}
								getSurvey={getSurvey}
							/>
						)}
					</Stack>
				</>
			) : (
				<>
					<Typography variant='subtitle1' component='legend'>
						{question.questionText}
					</Typography>
					<Stack direction='column' spacing={2}>
						<Button
							variant={selectedOption === 1 ? 'outlined' : 'contained'}
							color='info'
							size='large'
							onClick={() => handleOptionChange(1)}
							style={{ minWidth: '150px' }}
						>
							Yes
						</Button>
						<Button
							variant={selectedOption === 0 ? 'outlined' : 'contained'}
							color='error'
							size='large'
							onClick={() => handleOptionChange(0)}
							style={{ minWidth: '150px' }}
						>
							No
						</Button>
					</Stack>
				</>
			)}
		</Stack>
	);
};

export default YesNo;
