import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useEffect } from 'react';
import axios from '../../services/auth-header.js';
const Dashboard = () => {
	const { user } = useAppContext();
	const navigate = useNavigate();

	const getActiveSurvey = async () => {
		try {
			const result = await axios.get(`survey/response/active/${user.userId}`);
			const surveyResponse = result.data[0];
			if (surveyResponse) {
				navigate('/survey/respond');
			} else {
				navigate('/survey/invites');
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	useEffect(() => {
		if (!user.admin) {
			getActiveSurvey();
		} else {
			navigate('/admin/survey/manage');
		}
	}, []);
	return (
		<div className='flex justify-center w-full h-full min-h-0'>
			{!user.activated &&
				"You've been added to the waitlist! You'll be notified when your account is activated."}
		</div>
	);
};

export default Dashboard;
