import { Stack, Typography, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useAppContext } from '../../../../context/AppContext.jsx';
import axios from '../../../../services/auth-header.js';
import CreateSurveyModal from './CreateSurveyModal.jsx';
import { Survey } from '../../../../models/Survey.js';
import SurveyCard from './SurveyCard.jsx';
import Spinner from '../../../common/spinner/spinner.jsx';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SurveyTemplates = () => {
	const { user } = useAppContext();
	const [createSurveyOpen, setCreateSurveyOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [surveys, setSurveys] = useState([]);
	const [selectedSurveyId, setSelectedSurveyId] = useState('');
	const handleCreateOpen = () => setCreateSurveyOpen(true);
	const handleCreateClose = () => setCreateSurveyOpen(false);
	const navigate = useNavigate();

	useEffect(() => {
		getSurveys();
	}, []);
	const getSurveys = async () => {
		try {
			const response = await axios.get(`survey/owner/${user.userId}`);
			if (response.data) {
				setSurveys(response.data);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleReload = () => {
		getSurveys();
	};

	const createSurvey = async (title, intro) => {
		const survey = new Survey(title, intro, user.userId);
		try {
			const response = await axios.post('survey/create', survey);
			if (response.data.surveyId) {
				navigate(`/admin/survey/${response.data.surveyId}`);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const enableSurvey = async (participants, restaurantId, frequencyDays) => {
		try {
			const response = await axios.post('survey/enable', {
				participantCount: participants,
				restaurantId: restaurantId,
				frequencyDays: frequencyDays,
				surveyId: selectedSurveyId,
			});
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<Stack sx={{ marginTop: '2%', flexGrow: 1, minHeight: 0 }}>
			<Stack
				direction='row'
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '2%',
				}}>
				<Typography variant='h4'>Survey Templates</Typography>
				<Button
					variant='contained'
					onClick={handleCreateOpen}>
					Create Template
				</Button>
			</Stack>
			<Stack
				alignItems={'center'}
				spacing={2}
				className='h-full w-full min-h-0 overflow-y-scroll pt-4 pb-16'>
				{loading ? (
					<Spinner />
				) : (
					surveys.map((survey) => {
						return (
							<SurveyCard
								key={survey.surveyId}
								survey={survey}
								selectSurvey={setSelectedSurveyId}
								onSurveyDeleted={handleReload}
							/>
						);
					})
				)}
				<CreateSurveyModal
					open={createSurveyOpen}
					onClose={handleCreateClose}
					createSurvey={createSurvey}
				/>
			</Stack>
		</Stack>
	);
};

export default SurveyTemplates;
