export const QuestionType = Object.freeze({
	FIVE_STAR: 'FIVE_STAR',
	SLIDING_SCALE: 'SLIDING_SCALE',
	SHORT_TEXT: 'SHORT_TEXT',
	LONG_TEXT: 'LONG_TEXT',
	MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
	TIMER: 'TIMER',
	PICTURE: 'PICTURE',
	YES_NO: 'YES_NO',
});
