export class Survey {
	surveyId;
	surveyTitle;
	surveyIntro;
	ownerId;
	expirationDays;
	createdDate;
	updatedDate;
	constructor(surveyTitle, surveyIntro, ownerId) {
		this.surveyTitle = surveyTitle;
		this.surveyIntro = surveyIntro;
		this.ownerId = ownerId;
		this.expirationDays = 1;
		this.createdDate = new Date();
		this.updatedDate = new Date();
	}
}
