export class QuestionChoice {
	questionChoiceId;
	questionId;
	choiceIndex;
	choiceText;
	createdDate;
	updatedDate;
	constructor(questionId, choiceIndex, choiceText) {
		this.questionId = questionId;
		this.choiceIndex = choiceIndex;
        this.choiceText = choiceText;
		this.createdDate = new Date();
		this.updatedDate = new Date();
	}
}