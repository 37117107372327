export class QuestionBranch {
	questionBranchId;
	questionId;
	conditionalQuestionId;
	numberResponse;
	createdDate;
	updatedDate;
	constructor(questionId, conditionalQuestionId, numberResponse) {
		this.questionId = questionId;
		this.conditionalQuestionId = conditionalQuestionId;
		this.numberResponse = numberResponse;
		this.createdDate = new Date();
		this.updatedDate = new Date();
	}
}